<template>
  <div>
    <packing-profile-search />
    <md-progress-bar v-if="loading" md-mode="indeterminate" />

    <md-table
      v-if="profiles.content"
      class="analysis-create-table"
      :style="`min-height: ${height}px; height: ${height}px`"
    >
      <md-table-row>
        <md-table-head>
          <font-awesome-icon
            class="clickable"
            icon="times"
            @click="selectUnselectProfiles"
          />
        </md-table-head>
        <md-table-head>{{ $t("name") }}</md-table-head>
        <md-table-head>{{ $t("description") }}</md-table-head>
        <md-table-head></md-table-head>
      </md-table-row>
      <template v-for="profile in profiles.content">
        <md-table-row
          :key="profile.id"
          @click="selectUnselectProfile(profile.id)"
        >
          <md-table-cell>
            <input
              type="checkbox"
              v-model="selectedProfile"
              :value="profile.id"
              @mouseover="mouseover"
            />
          </md-table-cell>
          <md-table-cell>{{ profile.name }}</md-table-cell>
          <md-table-cell>
            {{ profile.description ? profile.description : "--" }}
          </md-table-cell>
          <md-table-cell>
            <md-button class="md-raised mg-left-0" @click="moreInfo(profile)">
              {{ $t("more_info") }}
            </md-button>
          </md-table-cell>
        </md-table-row>
      </template>
      <md-table-row
        v-if="!profiles.last"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        <md-table-cell colspan="3">
          {{ $t("load_more") }}
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div class="md-layout-item text-right">
      <a
        class="clickable"
        v-if="selectedProfile.length > 0"
        @click="selectedProfile = []"
      >
        {{ $t("remove_all") }}
      </a>
    </div>

    <packing-profile-dialog :showDialog.sync="showDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ANALYSIS_CREATE_SELECT_PACKING_P,
  PACKING_PROFILE_LOAD,
  PACKING_PROFILE_LOAD_MORE,
  PACKING_PROFILE_SELECT_DETAILS,
} from "../../store/actions.types";
import PackingProfileDialog from "../packingProfile/PackingProfileDialog.vue";
import PackingProfileSearch from "../packingProfile/PackingProfileSearch.vue";
export default {
  name: "AnalysisCreateStep3",
  components: {
    PackingProfileSearch,
    PackingProfileDialog,
  },
  props: {
    height: Number,
    clicked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      profiles: "getPackingProfiles",
    }),
  },

  data() {
    return {
      loading: true,
      selectedProfile: [],
      showDialog: false,
    };
  },

  watch: {
    selectedProfile: function () {
      this.$store.dispatch(
        ANALYSIS_CREATE_SELECT_PACKING_P,
        this.selectedProfile
      );
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      await this.$store.dispatch(PACKING_PROFILE_LOAD);
      this.loading = false;
    },

    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },

    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(PACKING_PROFILE_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },

    selectUnselectProfiles() {
      if (this.selectedProfile.length === this.profiles.content.length) {
        this.selectedProfile = [];
      } else {
        this.selectedProfile = [];
        this.profiles.content.forEach((profile) => {
          this.selectedProfile.push(profile.id);
        });
      }
    },

    moreInfo(profile) {
      this.$store.dispatch(PACKING_PROFILE_SELECT_DETAILS, profile);
      this.showDialog = true;
    },

    mouseover(ev) {
      if (this.clicked) {
        ev.target.click();
      }
    },

    selectUnselectProfile(profileId) {
      this.selectedProfile.includes(profileId)
        ? this.selectedProfile.splice(
            this.selectedProfile.indexOf(profileId),
            1
          )
        : this.selectedProfile.push(profileId);
    },
  },
};
</script>
