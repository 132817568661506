<template>
  <div>
    <md-dialog :md-active="showDialog">
      <md-dialog-title>{{ $t("packing_profile") }}</md-dialog-title>
      <md-dialog-content>
        <packing-profile-form
          ref="listPackingProfileForm"
          class="dialog-form"
          @onSubmit="onSubmit"
          :showSubmitBtn="false"
          :profile="profile"
          maxSize
        />
      </md-dialog-content>

      <md-dialog-actions>
        <md-button @click="close"> {{ $t("close") }} </md-button>
        <md-button class="md-raised" @click="update" :disabled="updating">
          {{ $t("update") }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PackingProfile } from "../../common/api/api.service";
import {
  PACKING_PROFILE_RELOAD,
  PACKING_PROFILE_SELECT_DETAILS,
} from "../../store/actions.types";
import PackingProfileForm from "./PackingProfileForm.vue";
export default {
  name: "PackingProfileDialog",
  components: {
    PackingProfileForm,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      profile: "getSelectedProfile",
    }),
  },

  data() {
    return {
      updating: false,
    };
  },

  methods: {
    close() {
      this.$store.dispatch(PACKING_PROFILE_SELECT_DETAILS, {});
      this.$emit("update:showDialog", false);
    },
    
    update() {
      this.$refs.listPackingProfileForm.submit();
    },

    onSubmit(form) {
      this.updating = true;
      PackingProfile.put(form)
        .then(() => {
          this.$store.dispatch(PACKING_PROFILE_SELECT_DETAILS, {});
          this.$store.dispatch(PACKING_PROFILE_RELOAD);
          this.$toast.success(this.$t("updated!"));
          this.$emit("update:showDialog", false);
        })
        .catch(() => {
          this.$toast.error(this.$t("error"));
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>

<style scoped>
.dialog-form {
  max-width: 850px;
}
</style>