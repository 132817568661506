<template>
  <md-field>
    <label> {{ $t("search") }} </label>
    <md-input ref="article-search-input" v-model="search" />
  </md-field>
</template>

<script>
import _ from "lodash";
import { ARTICLE_SEARCH } from "../../store/actions.types";
export default {
  name: "ArticleSearch",
  data() {
    return {
      search: "",
    };
  },

  watch: {
    search: function () {
      this.onSearchChange();
    },
  },

  methods: {
    onSearchChange: _.debounce(function () {
      this.$store.dispatch(ARTICLE_SEARCH, this.search);
    }, 500),
  },
};
</script>
