export const TextMixin = {
    methods: {
        cutText(text, maxLength) {
            if (text?.length > maxLength) {
                return `${text.substr(0, maxLength)}...`;
            } else {
                return text;
            }
        }
    }
}