<template>
  <div>
    <article-search />

    <md-progress-bar v-if="loading" md-mode="indeterminate" />

    <md-table
      v-if="articles.content"
      class="analysis-create-table"
      :style="`min-height: ${height}px; height: ${height}px`"
    >
      <md-table-row>
        <md-table-head>
          <font-awesome-icon class="clickable" icon="times" @click="selectUnselect" />
        </md-table-head>
        <md-table-head>
          {{ $t("reference") }}
        </md-table-head>
        <md-table-head>
          {{ $t("description") }}
        </md-table-head>
        <md-table-head> {{ $t("volume") }} (cm<sup>3</sup>) </md-table-head>
        <md-table-head> {{ $t("width") }} (mm) </md-table-head>
        <md-table-head> {{ $t("length") }} (mm) </md-table-head>
        <md-table-head> {{ $t("height") }} (mm) </md-table-head>
        <md-table-head> {{ $t("weight") }} (g) </md-table-head>
        <md-table-head>
          {{ $t("type") }}
        </md-table-head>
      </md-table-row>

      <template v-for="article in articles.content">
        <md-table-row :key="article.id" @click="selectUnselecArticle(article.id)">
          <md-table-cell>
            <input
              type="checkbox"
              v-model="selectedArticles"
              :value="article.id"
              @mouseover="mouseover"
            />
          </md-table-cell>
          <md-table-cell> {{ article.reference }} </md-table-cell>
          <md-table-cell>
            <span>
              {{
                article.description ? cutText(article.description, 15) : "--"
              }}
              <md-tooltip v-if="article.description">
                {{ article.description }}
              </md-tooltip>
            </span>
          </md-table-cell>
          <md-table-cell> {{ article.volume }} </md-table-cell>
          <md-table-cell> {{ article.width }} </md-table-cell>
          <md-table-cell> {{ article.length }} </md-table-cell>
          <md-table-cell> {{ article.height }} </md-table-cell>
          <md-table-cell> {{ article.weight }} </md-table-cell>
          <md-table-cell> {{ article.type }} </md-table-cell>
        </md-table-row>
      </template>

      <md-table-row
        v-if="!articles.last"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        <md-table-cell colspan="9"> {{ $t("load_more") }}... </md-table-cell>
      </md-table-row>
    </md-table>
    <div class="md-layout-item text-right">
      <a class="clickable" v-if="selectedArticles.length > 0" @click="selectedArticles = []">
        {{ $t("remove_all") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ANALYSIS_CREATE_SELECT_ARTICLE,
  ARTICLE_LOAD,
  ARTICLE_LOAD_MORE,
} from "../../store/actions.types";
import ArticleSearch from "../article/ArticleSearch.vue";
import { TextMixin } from "../../common/mixins/TextMixin";

export default {
  name: "AnalysisCreateStep1",
  components: {
    ArticleSearch,
  },
  mixins: [TextMixin],
  props: {
    height: Number,
    clicked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      selectedArticles: [],
    };
  },

  watch: {
    selectedArticles: function () {
      this.$store.dispatch(
        ANALYSIS_CREATE_SELECT_ARTICLE,
        this.selectedArticles
      );
    },
  },

  computed: {
    ...mapGetters({
      articles: "getArticles",
    }),
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      await this.$store.dispatch(ARTICLE_LOAD);
      this.loading = false;
    },

    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },

    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(ARTICLE_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },

    selectUnselect() {
      if (this.selectedArticles.length === this.articles.content.length) {
        this.selectedArticles = [];
      } else {
        this.selectedArticles = [];
        this.articles.content.forEach((article) => {
          this.selectedArticles.push(article.id);
        });
      }
    },

    mouseover(ev) {
      if(this.clicked) {
        ev.target.click();
      }
    },

    selectUnselecArticle(articleId) {
      this.selectedArticles.includes(articleId)
        ? this.selectedArticles.splice(this.selectedArticles.indexOf(articleId), 1)
        : this.selectedArticles.push(articleId);
    }
  },
};
</script>
