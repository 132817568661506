<template>
  <md-steppers :md-active-step.sync="step" ref="steppers" md-linear>
    <md-step id="first" :md-label="$t('article') + ` (${articles.length})`">
      <!-- Select article -->
      <analysis-create-step-1 :height="height" :clicked="clicked" />
      <md-button
        class="md-raised mg-left-0"
        @click="step = 'second'"
        :disabled="articles.length === 0"
      >
        {{ $t("next") }}
      </md-button>
    </md-step>

    <md-step id="second" :md-label="$t('box') + ` (${boxes.length})`">
      <analysis-create-step-2 :height="height" :clicked="clicked" />
      <md-button
        class="md-raised mg-left-0"
        @click="step = 'third'"
        :disabled="boxes.length === 0"
      >
        {{ $t("next") }}
      </md-button>
    </md-step>

    <md-step
      id="third"
      :md-label="$t('packing_profile') + ` (${profiles.length})`"
    >
      <analysis-create-step-3 :height="height" :clicked="clicked" />
      <md-button
        class="md-raised mg-left-0"
        @click="analyze"
        :disabled="profiles.length === 0 || submiting"
      >
        {{ $t("analyze") }}
      </md-button>
    </md-step>
  </md-steppers>
</template>

<script>
import { mapGetters } from "vuex";
import { Analysis } from "../../common/api/api.service";
import { ALERT } from '../../store/actions.types';
import AnalysisCreateStep1 from "./AnalysisCreateStep1.vue";
import AnalysisCreateStep2 from "./AnalysisCreateStep2.vue";
import AnalysisCreateStep3 from "./AnalysisCreateStep3.vue";
export default {
  name: "AnalysisCreateContent",
  components: {
    AnalysisCreateStep1,
    AnalysisCreateStep2,
    AnalysisCreateStep3,
  },
  props: {
    clicked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      articles: "getAnalysisCreateSelectedArticles",
      boxes: "getAnalysisCreateSelectedBoxes",
      profiles: "getAnalysisCreateSelectedProfiles",
    }),
  },
  data() {
    return {
      step: "first",
      height: 100,
      submiting: false,
    };
  },

  mounted() {
    this.calculateHeight();
    window.addEventListener("resize", this.calculateHeight);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateHeight);
  },

  methods: {
    calculateHeight() {
      let min = this.$refs.steppers.$el.getBoundingClientRect().top;
      let max = window.innerHeight;
      let size = max - min - 265;

      if (size < 250) {
        size = 250;
      }

      this.height = size;
    },

    analyze() {
      let params = {
        boxesId: this.boxes,
        articlesId: this.articles,
        profilesId: this.profiles,
      };
      this.submiting = true;
      Analysis.create(params)
        .then(() => {
          this.$router.push({ name: "Analysis" });
        })
        .catch(() => {
          this.$store.dispatch(ALERT, {message: "error_creating_analysis"});
        })
        .finally(() => {
          this.submiting = false;
        });
    },
  },
};
</script>

<style scoped>
.md-stepper {
  padding: 16px 0;
}
</style>
