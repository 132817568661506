<template>
  <div>
    <box-search />
    <md-progress-bar v-if="loading" md-mode="indeterminate" />

    <md-table
      v-if="boxes.content"
      class="analysis-create-table"
      :style="`min-height: ${height}px; height: ${height}px`"
    >
      <md-table-row>
        <md-table-head>
          <font-awesome-icon
            class=""
            icon="times"
            @click="selectUnselectBoxes"
          />
        </md-table-head>
        <md-table-head> {{ $t("reference") }} </md-table-head>
        <md-table-head> {{ $t("description") }} </md-table-head>
        <md-table-head> {{ $t("weight") }} (g) </md-table-head>
        <md-table-head> {{ $t("height") }} (mm)</md-table-head>
        <md-table-head> {{ $t("width") }} (mm)</md-table-head>
        <md-table-head> {{ $t("length") }} (mm) </md-table-head>
      </md-table-row>

      <template v-for="box in boxes.content">
        <md-table-row :key="box.id" @click="selectUnselecBox(box.id)">
          <md-table-cell>
            <input
              type="checkbox"
              v-model="selectedBoxes"
              @mouseover="mouseover"
              :value="box.id"
            />
          </md-table-cell>
          <md-table-cell>{{ box.reference }}</md-table-cell>
          <md-table-cell>
            <span>
              {{ box.description ? cutText(box.description, 25) : "--" }}
              <md-tooltip v-if="box.description">
                {{ box.description }}
              </md-tooltip>
            </span>
          </md-table-cell>
          <md-table-cell>{{ box.weight }}</md-table-cell>
          <md-table-cell>{{ box.height }}</md-table-cell>
          <md-table-cell>{{ box.width }}</md-table-cell>
          <md-table-cell>{{ box.length }}</md-table-cell>
        </md-table-row>
      </template>

      <md-table-row
        v-if="!boxes.last"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        <md-table-cell colspan="7"> {{ $t("load_more") }} </md-table-cell>
      </md-table-row>
    </md-table>

    <div class="md-layout-item md-size-100 text-right text-selection">
      <!-- <span v-if="selectedBoxes.length > 10">{{ selectedBoxes.length }}</span> -->

      <a
        class="clickable"
        v-if="selectedBoxes.length > 0"
        @click="selectedBoxes = []"
      >
        {{ $t("remove_all") }}</a
      >

      <a class="clickable" @click="selectAll">{{ $t("select_all") }}</a>

      <md-progress-spinner
        v-if="selectingAll"
        class="md-primary md-accent"
        md-mode="indeterminate"
        :md-diameter="10"
        :md-stroke="1"
      ></md-progress-spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Box } from "../../common/api/api.service";
import { TextMixin } from "../../common/mixins/TextMixin";
import {
  ANALYSIS_CREATE_SELECT_BOX,
  BOX_LOAD,
  BOX_LOAD_MORE,
} from "../../store/actions.types";
import BoxSearch from "../box/BoxSearch.vue";
export default {
  name: "AnalysisCreateStep2",
  components: {
    BoxSearch,
  },
  props: {
    height: Number,
    clicked: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [TextMixin],
  computed: {
    ...mapGetters({
      boxes: "getBoxes",
    }),
  },

  data() {
    return {
      loading: true,
      selectedBoxes: [],
      selectingAll: false,
    };
  },

  watch: {
    selectedBoxes: function () {
      this.$store.dispatch(ANALYSIS_CREATE_SELECT_BOX, this.selectedBoxes);
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      await this.$store.dispatch(BOX_LOAD);
      this.loading = false;
    },

    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },

    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(BOX_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },

    selectUnselectBoxes() {
      if (this.selectedBoxes.length === this.boxes.content.length) {
        this.selectedBoxes = [];
      } else {
        this.selectedBoxes = [];
        this.boxes.content.forEach((box) => {
          this.selectedBoxes.push(box.id);
        });
      }
    },

    selectAll() {
      if (!this.selectingAll) {
        this.selectingAll = true;

        Box.getIds()
          .then((res) => {
            this.selectedBoxes = res.data;
          })
          .finally(() => {
            this.selectingAll = false;
          });
      }
    },

    mouseover(ev) {
      if (this.clicked) {
        ev.target.click();
      }
    },

    selectUnselecBox(boxId) {
      this.selectedBoxes.includes(boxId)
        ? this.selectedBoxes.splice(this.selectedBoxes.indexOf(boxId), 1)
        : this.selectedBoxes.push(boxId);
    },
  },
};
</script>

<style scoped>
.text-selection a {
  margin-left: 10px;
}
</style>