<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'Analysis' }"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>
    <analysis-create-content ref="analysis-create-content" :clicked="clicked"/>
  </div>
</template>

<script>
import AnalysisCreateContent from "../../components/analysis/AnalysisCreateContent.vue";
import {
  ANALYSIS_CREATE_SELECT_ARTICLE,
  ANALYSIS_CREATE_SELECT_BOX,
  ANALYSIS_CREATE_SELECT_PACKING_P,
  ARTICLE_UNLOAD,
  BOX_UNLOAD,
  PACKING_PROFILE_UNLOAD,
  TOOLBAR_TITLE,
} from "../../store/actions.types";
export default {
  name: "AnalysisCreate",
  components: {
    AnalysisCreateContent,
  },
  data() {
    return {
      clicked: false,
    };
  },
  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "analysis_create");
    this.$refs["analysis-create-content"].$el.addEventListener(
      "mousedown",
      this.mouseClick
    );
    this.$refs["analysis-create-content"].$el.addEventListener(
      "mouseup",
      this.mouseUnclick
    );
  },
  beforeDestroy() {
    this.$store.dispatch(ARTICLE_UNLOAD);
    this.$store.dispatch(BOX_UNLOAD);
    this.$store.dispatch(PACKING_PROFILE_UNLOAD);
    this.$store.dispatch(ANALYSIS_CREATE_SELECT_ARTICLE, []);
    this.$store.dispatch(ANALYSIS_CREATE_SELECT_BOX, []);
    this.$store.dispatch(ANALYSIS_CREATE_SELECT_PACKING_P, []);

    this.$refs["analysis-create-content"].$el.removeEventListener(
      "mouseover",
      this.mouseClick
    );
    this.$refs["analysis-create-content"].$el.removeEventListener(
      "mouseup",
      this.mouseUnclick
    );
  },
  methods: {
    mouseClick() {
      this.clicked = true;
    },
    mouseUnclick() {
      this.clicked = false;
    },
  },
};
</script>

<style>
input[type="checkbox"] {
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.analysis-create-table {
  max-height: 500px;
}
</style>